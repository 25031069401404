import React from "react"
import { graphql, withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/plaza-city"

import { ApartmentsPromo } from "page_components/home"

const Investment = ({ data, location }) => {
  const title = "Плаза Сіті"
  const investment = "Plaza City"
  const investmentRaw = "plaza_city"

  const isSmartEstatePromoEnabled =
    data.allWp.nodes[0].smartEstate.acfEstateSettings
      .promotedApartmentsPlazaCityDisplay
  const smartEstatePromo =
    data.allWp.nodes[0].smartEstate.acfEstateSettings
      .promotedApartmentsPlazaCity

  return (
    <Layout
      location={location}
      seo={{
        title: `${title}`,
        image: withPrefix("/pc-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Ченстохова", url: "/ua/kvartyry/chenstokhova" }}
        subParent={{ label: "Квартири", url: "/ua/kvartyry" }}
      />
      <Hero title={title} />
      <Nav title={title} investment={title} investmentRaw={investmentRaw} />
      <ApartmentsPromo
        type="subpage"
        allApartmentsPromo={[]}
        smartEstatePromo={
          isSmartEstatePromoEnabled
            ? [
                {
                  investment: "plaza_city",
                  apartments: smartEstatePromo,
                },
              ]
            : undefined
        }
        investmentRaw={investmentRaw}
      />
      <Apartments investment={investment} investmentRaw={investmentRaw} />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment={investment}
        investmentRaw={investmentRaw}
      />
      {/* <Popup type="czestochowa" /> */}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWp {
      nodes {
        smartEstate {
          acfEstateSettings {
            promotedApartmentsPlazaCityDisplay
            promotedApartmentsPlazaCity {
              view {
                localFile {
                  publicURL
                }
              }
              data {
                number
                id
                rooms
                floor
                area
                price {
                  basic
                  promo
                  save
                  lowest
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Investment
